<template>
  <v-row dense class="flex-md-nowrap">
    <v-col cols="12" sm="6" md="auto" class="flex-shrink-0 flex-grow-1">
      <v-select
          hide-details
          :value="client_uuid"
          @change="(v) => setFilter('client_uuid', v)"
          clearable @click:clear="setFilter('client_uuid', null)"
          item-text="name"
          item-value="uuid"
          :items="clientList"
          :disabled="$store.state.auth.role === 'client' || loading"
          :readonly="$store.state.auth.role === 'client'"
          outlined dense flat label="Client name"></v-select>
    </v-col>
    <v-col cols="12" sm="6" md="auto" class="flex-shrink-0 flex-grow-1">
      <v-text-field hide-details
                    @change="(v) => setFilter('num', v)"
                    clearable @click:clear="setFilter('num', null)"
                    :disabled="loading"
                    outlined dense flat label="Order #"></v-text-field>
    </v-col>
    <v-col cols="12" sm="6" md="auto" class="flex-shrink-0 flex-grow-1">
      <v-menu
          ref="menu"
          :close-on-content-click="false"

          :return-value="dates"
          v-on:update:return-value="setDateFilter"

          transition="scale-transition"
          offset-y
          min-width="auto"
          v-model="menu"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field hide-details
                        :disabled="loading"
                        :value="dateRangeText"
                        label="Date range"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        dense
                        outlined
                        clearable @click:clear="setFilter('dates', null)"
          ></v-text-field>
        </template>
        <v-date-picker
            v-model="date"
            no-title
            range
        >
          <v-spacer></v-spacer>
          <v-btn
              text
              color="primary"
              @click="menu = false"
          >
            Cancel
          </v-btn>
          <v-btn
              text
              color="primary"
              @click="$refs.menu.save(date)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
    </v-col>
    <v-col cols="12" sm="6" md="auto" class="flex-grow-0 flex-shrink-1 text-right">
      <v-btn :loading="loading" :disabled="loading || isIdle" color="grey lighten-2" height="40" depressed @click="resetFilters">
        <span class="grey--text">Reset</span>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import {mapState} from "vuex";
import {mapFields} from "vuex-map-fields";
import {get} from "lodash";

export default {
  name: "OrderFilter",
  props: [
      'loading',
      'client_uuid',
      'num',
      'dates',
  ],
  data: () => ({
    menu: false,
    date: [],
  }),
  computed: {
    ...mapState('registry', {
      clientList: 'client'
    }),
    isIdle() {
      return !(get(this, 'client_uuid') != null || get(this, 'num') != null || get(this, 'dates') != null);
    },
    dateRangeText() {
      if (this.dates && this.dates.length > 1) {
        return this.dates.join(' ~ ')
      }
      return '';
    },
  },
  methods: {
    resetFilters() {
      if (this.$store.state.auth.role !== 'client') this.$emit('update:client_uuid', null);
      this.$emit('update:num', null);
      this.$emit('update:dates', null);
      this.$emit('change');
    },
    setFilter(key, value = null) {
      this.$emit(`update:${key}`, value);
      this.$emit('change');
    },
    setDateFilter(v) {
      if (v && v instanceof Array && v.length > 1) {
        this.$emit('update:dates', v)
      } else {
        this.$emit('update:dates', null)
      }
      this.$emit('change')
    }
  }
}
</script>

<style scoped>

</style>