<template>
  <v-layout fill-height column class="grey lighten-5">
    <v-container fluid>
      <v-row align="center" justify="center" align-content="center" class="py-3">
        <v-col cols="12" md="3" class="flex-grow-0">
          <h1>Orders</h1>
        </v-col>
        <v-col cols="12" md="6" class="d-flex column">
          <OrderFilter
              v-bind:client_uuid.sync="filterClientUuid"
              v-bind:num.sync="filterNum"
              v-bind:dates.sync="filterDate"
              v-on:change="() => applyFilter()"
              :loading="loading"
          ></OrderFilter>
        </v-col>
        <v-col cols="12" md="3" class="d-flex flex-grow-0">
          <v-spacer></v-spacer>
          <v-btn
              v-if="(role === 'admin' || role === 'manager')"
              :to="{name: 'order.create', params: {}}"
              color="green"
              depressed
              dark>Add new
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

      <Pagination
          :items="items"
          :loading="loading"
          @update="onUpdatePaging"
          :pagination="pagination"
      >
        <template v-slot:default="{items}">
          <v-container fluid class="fill-height column align-start">
            <v-row>
              <v-col
                  v-for="item in items"
                  :key="`col-${item.uuid}`"
                  cols="12"
                  sm="6"
                  md="4"
                  lg="3"
              >
                <OrderCard
                    :key="item.uuid"
                    :loading="instanceLoading === item.uuid"
                    :item="item"
                ></OrderCard>
              </v-col>
            </v-row>
          </v-container>
        </template>
      </Pagination>
  </v-layout>
</template>

<script>
import {makeCrudView} from "../../store/crud";
import {mapFields, mapMultiRowFields} from "vuex-map-fields";
import Pagination from "../../components/common/Pagination";
import OrderCard from "../../components/common/OrderCard";
import {mapActions, mapState} from "vuex";
import OrderFilter from "../../components/order/OrderFilter";

export default {
  name: "OrderList",
  props: {
    manager_uuid: null,
    client_uuid: null,
    // role: null,
  },
  components: {OrderFilter, OrderCard, Pagination},
  extends: makeCrudView('order'),
  computed: {
    ...mapState('auth', {
      role: 'role'
    }),
    ...mapState('registry', {
      clientList: 'client'
    }),
    ...mapFields('order', {
      'page': 'query.page',
      'limit': 'query.limit',
      'filterClientUuid': 'query.client_uuid',
      'filterNum': 'query.number',
      'filterDate': 'query.date',
    }),
    dateRangeText() {
      if (this.dates.length > 1) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.filterDate = this.dates;
      }
      return this.dates.join(' ~ ')
    },
  },
  beforeMount() {
    // this.filterClientUuid = null;
    if (this.$props.client_uuid) this.filterClientUuid = this.$props.client_uuid;
  },
  mounted() {
    this.loadRegistry('client')
  },
  methods: {
    ...mapActions('registry', {
      loadRegistry: 'load'
    }),
    onUpdatePaging({page, limit}) {
      this.page = page;
      this.fetch();
    },
    applyFilter() {
      this.fetch();
    },
  },
  data: () => ({
    date: null,
    dates: []
  })
}
</script>