<template>
  <v-card
    :to="{ name: 'order.show', params: { uuid: item.uuid } }"
    outlined
    :loading="loading"
    :hover="true"
    class="d-flex flex-column fill-height"
    style="user-select: none; overflow: hidden"
    @dragstart.prevent="() => false"
  >
    <v-container fluid>
      <v-row align-content="start" dense class="flex-nowrap">
        <v-col cols="10" class="d-flex flex-column flex-grow-1 flex-shrink-0">
          <div class="d-flex column align-center">
            <h4>{{ name }}</h4>
            <span
              v-if="delayed"
              class="ml-2 error--text text-body-2 font-weight-bold"
              >delayed</span
            >
          </div>
          <div
            class="d-flex flex-wrap align-content-start mt-3"
            style="min-height: 5em"
          >
            <template v-for="(loc, index) in routeReadable">
              <div
                :key="index"
                :title="loc"
                class="text-truncate"
                style="max-width: 120px"
              >
                {{ loc }}
              </div>
              <v-icon
                class="mx-2"
                :key="`i-${index}`"
                v-if="index < routeReadable.length - 1"
                small
                >mdi-arrow-right</v-icon
              >
            </template>
          </div>
        </v-col>
        <v-col class="ml-auto flex-grow-0 flex-shrink-0">
          <TransportSelect
            readonly
            :value="currentState.transport"
          ></TransportSelect>
        </v-col>
      </v-row>
      <div class="mt-3">
        <StateBadge :value="item"></StateBadge>
      </div>
    </v-container>
  </v-card>
</template>

<script>
import Avatar from "./Avatar";
import { get, map, find } from "lodash";
import StateBadge from "../order/StateBadge";
import moment from "moment";
import TransportSelect from "../order/TransportSelect";

export default {
  name: "OrderCard",
  components: { TransportSelect, StateBadge },
  props: {
    item: {
      type: Object,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    name: function () {
      const orderName = this.item.name;

      return orderName ? orderName : `Order #${this.item.num}`;
    },
    routeReadable: function () {
      let route = get(this.item, "route", []);
      return map(route, (r) => r.location.place_name);
    },
    // TODO Replace after backend fix
    currentState: function () {
      return find(
        this.item.route,
        (r) => r.uuid === this.item.current_state.uuid
      );
    },
    delayed: function () {
      let waypoint = this.currentState;
      let today = moment().endOf("day");
      let until = moment(waypoint.scheduled_at).endOf("day");
      let isUncompleted = !waypoint.completed_at;
      return isUncompleted && today.isAfter(until);
    },
  },
};
</script>
