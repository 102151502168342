<template>
  <div :title="currentStateText">
    <v-chip
        :class="`${color}--text ${color} lighten-5`"
        label><div class="font-weight-medium text-truncate">{{ currentStateText }}</div></v-chip>
  </div>
</template>

<script>
import {find} from "lodash";
import states from "../../store/enums/states";
import {Waypoint} from "../../store/models/WaypointState";
export default {
  name: "StateBadge",
  props: {
    value: {
      type: [Object, Waypoint], // Waypoint Object
      default: () => null,
    }
  },
  data: () => ({
    states: states,
  }),
  computed: {
    /**
     * @returns {Waypoint}
     */
    currentWaypoint: function() {
      return find(this.value.route, (r) => {
        return r.uuid === this.value.current_state.uuid;
      });
    },
    currentStateId: function() {
      let waypoint = this.currentWaypoint;
      return waypoint.state
    },
    currentStateText: function() {
      let waypoint = this.currentWaypoint;
      return `${waypoint.state} ${this.date(waypoint.scheduled_at, 'DD/MM/YY')} to ${waypoint.location.place_name}`
    },
    color: function() {
      return this.mapStateColor(this.currentStateId);
    }
  },
  methods: {
    mapStateColor(state) {
      const found = find(this.states, (e) => e.value === state);
      if (found) return found.color;
      return 'error';
    },
  }
}
</script>